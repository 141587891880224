import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://70ff7fce1c5341971774113457dac069@o86468.ingest.us.sentry.io/4507028191903744',
	tracesSampleRate: 1.0,

	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,

	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,

	// If you don't want to use Session Replay, just remove the line below:
	// Commenting the line as it failed the deployment
	// integrations: [replayIntegration()],

	environment: import.meta.env.MODE
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`

export const handleError = handleErrorWithSentry();
// export const handleError: HandleClientError = (input) => {
// 	const { error, event } = input;
// 	console.log('error11:', error);
// 	console.log('event22:', event);
// 	// return handleErrorWithSentry()(input);
// };
